export function getToken() {
  return localStorage.getItem('accessToken')
}

export function getUser() {
  return localStorage.getItem('xUser')
}

export function getCompany() {
  return localStorage.getItem('xCompany')
}