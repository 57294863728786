import axios from 'axios';
import Config from '../config';
import store from '../redux';
import { startCase } from 'lodash';
import { decrypt, encrypt } from './encrypt';
import { getCompany, getToken, getUser } from './token';
import { setMessage } from '../redux/reducer';

const state = store.getState()

const axiosInstance = axios.create({
    baseURL: Config.BASE_URL,
    timeout: 60000, // 1 Min
    header: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        Authorization: state.auth.isAuthenticated ? 'Bearer ' + state.user.Token : undefined
    }
});

axiosInstance.interceptors.request.use(
    (request) => {
        // const encryptedData = encrypt(request.data)
        // request['data'] = { hashed: encryptedData }
        return request
    }
)
axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
            return decrypt(response.data.hashed)
        }

        if (response.status === 204 || response.status === 400 || response.status === 401 || response.status === 404) {
            return response.data
        }

        return { error: 'Unknown error on client occurred' }
    },
    (error) => {
        switch (error.response.status) {
            case 400:
            case 402:
            case 403:
            case 420:
            case 421:
            case 422:
            case 423: {
                const key = Object.keys(error.response.data.errors)[0]
                const message = error.response.data.errors[key]
                return { status: error.response.status, message: `${startCase(key)} ${message}` }
            }
            default:
                return { status: error.response.status, message: error.response.data }
        }
    }
);

export default axiosInstance

const axiosWithToken = (auth, dispatch, navigate) => {
    const axiosWithInstance = axios.create({
        baseURL: Config.BASE_URL,
        timeout: 60000, // 1 Min
        header: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
            Authorization: state.auth.isAuthenticated ? 'Bearer ' + state.user.Token : undefined
        }
    });
    axiosWithInstance.defaults.headers.common.Authorization = 'Bearer ' + getToken()
    axiosWithInstance.defaults.headers.common['x-user'] = getUser()
    axiosWithInstance.defaults.headers.common['x-company'] = getCompany()
    axiosWithInstance.interceptors.response.use(
        (response) => {
            if (response.status === 401) {
                if (auth) {
                    // auth.logout().then(() => console.log('Session ended'))
                }

                // dispatch(setMessage('Your user session has ended. Please login'))
                // navigate('/login')
                return { error: 'Your user session has ended. Please login' }
            }

            if (response.status === 200 || response.status === 201 || response.status === 202) {
                return decrypt(response.data.hashed)
            }

            if (response.status === 204 || response.status === 400 || response.status === 401 || response.status === 404) {
                return response.data
            }

            return { error: 'Unknown error on client occurred' }
        },
        (error) => {
            switch (error.response.status) {
                case 401: {
                    if (auth) {
                        auth.logout().then(() => console.log('Session ended'))
                        dispatch(setMessage('Your user session has ended. Please login'))
                        navigate('/login')
                    }
                }
                case 400:
                case 402:
                case 403:
                case 420:
                case 421:
                case 422:
                case 423: {
                    const key = Object.keys(error.response.data.errors)[0]
                    const message = error.response.data.errors[key]
                    return { status: error.response.status, message: `${startCase(key)} ${message}` }
                }
                default:
                    return { status: error.response.status, message: error.response.data }
            }
        }
    );
    return axiosWithInstance
}

const axiosWithBlob = () => {
    const axiosWithInstance = axios.create({
        baseURL: Config.BASE_URL,
        timeout: 60000, // 1 Min
        header: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
            Authorization: state.auth.isAuthenticated ? 'Bearer ' + state.user.Token : undefined
        },
        responseType: 'blob'
    });

    axiosWithInstance.defaults.headers.common.Authorization = 'Bearer ' + getToken()
    axiosWithInstance.defaults.headers.common['x-user'] = getUser()
    axiosWithInstance.defaults.headers.common['x-company'] = getCompany()
    axiosWithInstance.interceptors.response.use(
        (response) => {
            return response.data
        },
        (error) => {
            switch (error.response.status) {
                case 400:
                case 402:
                case 403:
                case 420:
                case 421:
                case 422:
                case 423: {
                    const key = Object.keys(error.response.data.errors)[0]
                    const message = error.response.data.errors[key]
                    return { status: error.response.status, message: `${startCase(key)} ${message}` }
                }
                default:
                    return { status: error.response.status, message: error.response.data }
            }
        }
    )

    return axiosWithInstance
}

const axiosWithFile = (responseType = undefined, callback = () => { }) => {
    const axiosWithInstance = axios.create({
        baseURL: Config.BASE_URL,
        timeout: 60000, // 1 Min
        header: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Methods': 'PUT, POST, OPTIONS',
            Authorization: state.auth.isAuthenticated ? 'Bearer ' + state.user.Token : undefined
        },
        responseType
    });

    axiosWithInstance.defaults.headers.common.Authorization = 'Bearer ' + getToken()
    axiosWithInstance.defaults.headers.common['x-user'] = getUser()
    axiosWithInstance.defaults.headers.common['x-company'] = getCompany()
    axiosWithInstance.interceptors.response.use(
        (response) => {
            return response.data
        },
        (error) => {
            switch (error.response.status) {
                case 400:
                case 402:
                case 403:
                case 420:
                case 421:
                case 422:
                case 423: {
                    const key = Object.keys(error.response.data.errors)[0]
                    const message = error.response.data.errors[key]
                    return { status: error.response.status, message: `${startCase(key)} ${message}` }
                }
                case 406: {
                    const message = error.response.data.text()
                    callback(message)
                    return { status: error.response.status, message: message }
                }
                default: {
                    const message = error.response.data.text()
                    callback(message)
                    return { status: error.response.status, message: message }
                }
            }
        }
    )

    return axiosWithInstance
}

export { axiosWithToken, axiosWithBlob, axiosWithFile }