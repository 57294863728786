const {
    REACT_APP_APP_ENV,
    REACT_APP_TARGET_ENV,
    REACT_APP_PROJECT_NAME,
    REACT_APP_AUTH_URL,
    REACT_APP_BASE_URL,
    REACT_APP_PUBLIC_KEY,
    REACT_APP_STORAGE_URL,
    REACT_APP_THEME,
    NODE_ENV,
    SERVER_PORT,
} = process.env

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET
};

const Config = {
    isProduction: REACT_APP_TARGET_ENV === 'production',
    isTest: REACT_APP_TARGET_ENV === 'test',
    isLocal: REACT_APP_TARGET_ENV === 'local',
    TARGET_ENV: REACT_APP_TARGET_ENV,
    APP_ENV: REACT_APP_APP_ENV,
    NAME: REACT_APP_PROJECT_NAME,
    THEME: REACT_APP_THEME,
    AUTH_URL: REACT_APP_AUTH_URL,
    BASE_URL: REACT_APP_BASE_URL,
    PUBLIC_KEY: REACT_APP_PUBLIC_KEY,
    FB_URL: REACT_APP_STORAGE_URL,
    NODE_ENV,
    SERVER_PORT: parseInt(SERVER_PORT, 10),
}

export default Config

