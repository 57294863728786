import React, {
    createContext
} from 'react';
import { useDispatch } from 'react-redux';
import { storeLogin, storeLogout } from '../redux/reducer'
import axios from '../utils/axios';
import Cookies from 'js-cookie';
import { encrypt } from '../utils/encrypt';

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    isVerified: false
};

const setSession = (accessToken, user, company) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('xUser', user);
        localStorage.setItem('xCompany', company);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('xUser');
        localStorage.removeItem('xCompany');
        Cookies.remove('rfsrt')
        delete axios.defaults.headers.common.Authorization;
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    login: () => Promise.resolve(),
    accounts: () => Promise.resolve(),
    authenticate: () => Promise.resolve(),
    forgot: () => Promise.resolve(),
    reset: () => Promise.resolve(),
    logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();

    // Start the user session
    const login = async ({ reference }) => {
        try {
            const response1 = await axios.post('/auth/login', {
                data: {
                    reference: reference
                }
            })

            if (response1.mobilePhone) {
                return { success: true, authenticate: true, mobilePhone: response1.mobilePhone }
            } else {
                return { error: response1.message }
            }
        } catch (error) {
            switch (error.message) {
                case 'Error: (auth/user-not-found).':
                    return { error: 'Account not found' }
                case 'Error: (auth/invalid-password).':
                    return { error: 'Password is invalid' }
                case 'Error: (auth/wrong-password).':
                    return { error: 'Incorrect password' }
                case 'Error: (auth/invalid-email).':
                    return { error: "Email address is invalid" }
                case 'Error: (auth/netword-request-failed).':
                    return { error: "No connection to the internet found" }
                default:
                    return { error: error.message }
            }
        }
    };

    // Authenticate user
    const authenticate = async ({ mobilePhone, otp }) => {
        try {
            const response1 = await axios.post('/auth/authenticate', {
                data: {
                    mobilePhone: mobilePhone,
                    otp: otp
                }
            })
            if (response1.token) {
                setSession(response1.token)
                Cookies.set('rfsrt', response1.token);

                const response2 = await axios.get('/auth')

                if (response2.data) {
                    const client = response2.data.client || {}
                    setSession(response1.token, `${response2.data.firstName} ${response2.data.lastName}`, client.name)
                    dispatch(storeLogin({
                        user: response2.data
                    }))
                    return { success: true }
                }
                return { error: response2.message }
            } else {
                return { error: response1.message }
            }
        } catch (error) {
            return { error: error.message }
        }
    };

    // Forgot password
    const forgot = async (email) => {
        try {
            const response = await axios.post('/auth/forgot', {
                data: {
                    email: email
                }
            })

            if (response.data && response.data.message) {
                return { success: true, message: response.message }
            } else {
                return { error: response.message }
            }
        } catch (error) {
            return { error: error.message }
        }
    };

    // Reset password
    const reset = async (token, password) => {
        try {
            const encrypted = encrypt(password)
            const response = await axios.post('/auth/reset', {
                data: {
                    password: encrypted,
                    token: token
                }
            })

            if (response.data) {
                return { success: true }
            } else {
                return { error: response.message }
            }
        } catch (error) {
            return { error: error.message }
        }
    };

    const logout = async () => {
        setSession(null, null, null);
        await axios.post('/account/logout', {
            data: {}
        })
        dispatch(storeLogout());
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                forgot,
                reset,
                logout,
                authenticate
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;