import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment-timezone';
import { REHYDRATE } from "redux-persist";

let initialState = {
    loginMessage: null,
    user: {},
    errorMessage: null,
    auth: {
        isLoggedIn: false,
        isAuthenticated: false,
        isVerified: false,
        lastLoggedIn: null
    }
};

const slice = createSlice({
    name: "main",
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.menuVisible = !state.menuVisible
        },
        storeLogin: (state, action) => {
            state.loginMessage = null
            const user = action.payload.user
            const client = (action.payload.user || {}).client
            const branch = (action.payload.user || {}).branch

            delete user['client']
            delete user['branch']

            state.user = user
            state.auth.isLoggedIn = true
            state.auth.lastLoggedIn = moment().toISOString()

            state.client = client
            state.branch = branch

            state.menuVisible = true

            // TODO remove
            state.auth.isAuthenticated = true
            state.auth.isVerified = true
        },
        storeAuthenticate: (state) => {
            state.auth.isAuthenticated = true
        },
        storeVerify: (state) => {
            state.auth.isVerified = true
        },
        storeLogout: (state) => {
            state = initialState
        },
        setMessage: (state, action) => {
            state.loginMessage = action.payload
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
        clearAll: (state) => {
            state = {}
        }
    },
    extraReducers: {
        [REHYDRATE]: (state) => {
            state.loginMessage = ''
        }
    }
});

export const {
    toggleSidebar,
    storeLogin,
    setMessage,
    storeLogout,
    clearAll
} = slice.actions;
const { reducer } = slice;

export const main = reducer

export default slice;
