import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import {
    configureStore, createAction,
} from "@reduxjs/toolkit";
import {
    persistStore, persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import config from "../config";

import { main } from "./reducer";

const RESET_STORE_ACTION_TYPE = "RESET";

const persistConfig = {
    key: config.isProduction ? `mayais-account-prod` : config.isTest ? `mayais-account-test` : `mayais-account-local`,
    storage
};

export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();

export const resetStoreAction = createAction(RESET_STORE_ACTION_TYPE);

const persistedReducer = persistReducer(persistConfig, main);

const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);

export default store;
