import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { IdleTimerProvider } from 'react-idle-timer';
import { useSelector, useDispatch } from '../redux';
import { setMessage } from '../redux/reducer';
import moment from 'moment-timezone';

const AuthGuard = ({ children }) => {
    const auth = useAuth();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const store = useSelector((state) => state);

    useEffect(() => {
        if (store && store.auth?.isLoggedIn) {
            if (store.auth?.lastLoggedIn && moment().diff(store.auth?.lastLoggedIn, 'minutes') < 20) {
                // Do nothing
            } else {
                auth.logout().then(() => console.log('Session ended'))
                dispatch(setMessage('Your session expired'))
                navigate('/login')
            }
        }
    }, [])

    const onPrompt = () => {
        // Fire a Modal Prompt
    }

    const onIdle = () => {
        auth.logout().then(() => console.log('Session ended'))
        dispatch(setMessage('Your session expired'))
        navigate('/login')
    }

    const onActive = (event) => {
        dispatch(setMessage(''))
    }

    const onAction = (event) => {
        // Do something when a user triggers a watched event
    }

    // if (!store.auth.isAuthenticated) {
    //     return <Navigate to="/login" />;
    // }

    return (

        <IdleTimerProvider
            timeout={1000 * 60 * 60} // 10 minutes
            onPrompt={onPrompt}
            onIdle={onIdle}
            onActive={onActive}
            onAction={onAction}
        >
            {children}
        </IdleTimerProvider>
    );
};

export default AuthGuard;
