import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux";
import moment from 'moment-timezone';

import * as serviceWorker from './serviceWorker';

import Config from './config';
if (Config.isProduction) {
    console.error = () => { }
    console.log = () => { }
    console.warn = () => { }
    console.debug = () => { }
} else {
    console.warn = () => { }
}

moment.tz.setDefault("Africa/Johannesburg");

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default
        ReactDOM.render(
            <NextApp />,
            document.getElementById('root')
        )
    })
}
