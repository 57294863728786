import CryptoJS from 'crypto-js';

export const encrypt = (body) => {
  const converted = JSON.stringify(body)
  return CryptoJS.AES.encrypt(converted, 'X_KEY_' + (new Date().toISOString().split('T')[0])).toString()
}

export const decrypt = (encryption) => {
  const decrypted = CryptoJS.AES.decrypt(encryption, 'X_KEY_' + (new Date().toISOString().split('T')[0])).toString(CryptoJS.enc.Utf8)
  return JSON.parse(decrypted)
}