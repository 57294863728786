import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from '../redux';
import moment from 'moment-timezone'

const GuestGuard = ({ children }) => {
    const store = useSelector((state) => state);
    // const navigate = useNavigate()
    // const dispatch = useDispatch()

    // if (store && store.auth.isAuthenticated) {
    //     return <Navigate to="/overview" />;
    // }

    // useEffect(() => {
    //     if (store && store.auth?.lastLoggedIn && moment().diff(store.auth?.lastLoggedIn, 'minutes') < 400) {
    //         // Do nothing
    //     } else {
    //         auth.logout().then(() => console.log('Session ended'))
    //         dispatch(setMessage('Your session was ended'))
    //         navigate('/login')
    //     }
    // }, [])

    // if (store && store.auth?.lastLoggedIn && moment().diff(store.auth?.lastLoggedIn, 'minutes') < 400) {
    //     return <Navigate to="/account" />;
    // }

    return (
        <>
            {children}
        </>
    );
};

export default GuestGuard;
