import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import FullPageLayout from '../components/layouts/FullPage';
import DefaultLayout from '../components/layouts/DefaultLayout'
import AuthGuard from '../components/AuthGuard';
import GuestGuard from '../components/GuestGuard';

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    layout: FullPageLayout,
    path: '/login',
    component: lazy(() => import('../containers/policies/Login')),
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: FullPageLayout,
    path: '/forgot-password',
    component: lazy(() => import('../containers/policies/ForgotPassword')),
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: FullPageLayout,
    path: '/reset-password/:token',
    component: lazy(() => import('../containers/policies/ResetPassword')),
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: FullPageLayout,
    path: '/authentication',
    component: lazy(() => import('../containers/policies/Authentication')),
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DefaultLayout,
    path: '/account',
    component: lazy(() => import('../containers/policies/MyAccount')),
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: FullPageLayout,
    path: '/signout',
    component: lazy(() => import('../containers/policies/SignOut')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/update',
    layout: FullPageLayout,
    component: lazy(() => import('../containers/Update')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    layout: FullPageLayout,
    component: lazy(() => import('../containers/policies/MyAccount')),
  },
  {
    path: '*',
    exact: true,
    guard: GuestGuard,
    layout: FullPageLayout,
    component: lazy(() => import('../containers/Errors')),
  }
]

export const renderRoutes = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              element={
                <Guard>
                  <Layout>
                    {route.routes
                      ? renderRoutes(route.routes)
                      : <Component />}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  )
}
