import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/thicccboi.css';
import './assets/styles/shards-dashboards.1.1.0.min.css';
import './assets/main.css';
import './assets/custom.css';

import { createBrowserHistory } from 'history';
import { AuthProvider } from './contexts/AuthContext';
import { renderRoutes } from './routes';
import { Provider } from 'react-redux';
import store, { persistor } from './redux';
import { PersistGate } from 'redux-persist/integration/react'
import Helmet from 'react-helmet';

const history = createBrowserHistory();

const App = (props) => {

  return (
    <>
      <Helmet>
        <title>
          MAYA | My Account
        </title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
        />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
        />
        <link key='font' href='https://fonts.googleapis.com/css?family=Figtree:100,200,300,400,500,600,700,800'
          rel='stylesheet' />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon.png"
        />
        <link
          rel="icon"
          href="/favicon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon.png"
        />
        <meta
          name="theme-color"
          content="#030852"
        />
        <meta property="og:title" content="MAYA | My Account" />
        <meta property="og:image" content="/thumbnail.png" />
        <meta property="og:description" content="My Account | MAYA" />
        <meta property="og:url" content="https://mayais.co.za" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
      </Helmet>

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <AuthProvider>
              {renderRoutes(store.getState())}
            </AuthProvider>
          </Router>
        </PersistGate>
      </Provider>

    </>
  );
};

export default App;
