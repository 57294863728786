
import React from "react";
import { Container, Row, Col, NavbarBrand } from "shards-react";

const IMG_LOGO = require('../../assets/images/logo-white.png');

const FullPage = ({ children }) => {
    const background = { background: '#185ADB' }
    return (
        <Container fluid className='full-page' style={background}>
            <Row>
                <Col
                    className="main-content p-0"
                    sm="12"
                    md='12'
                    tag="main"
                >
                    <div className='center-block'>
                        {children}
                        <div className='center-footer'>
                            <div>
                                <h6>Powered By</h6>
                            </div>
                            <NavbarBrand
                                href='https://mayais.co.za'
                            >
                                <img
                                    id='main-logo'
                                    className='align-top ml-2'
                                    style={{ maxWidth: '100px', height: '100%' }}
                                    src={IMG_LOGO}
                                />
                            </NavbarBrand>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default FullPage;
