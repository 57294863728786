import React from 'react';
import classNames from 'classnames';
import { Col } from 'shards-react';
import { Oval } from 'react-loader-spinner';

const LoadingScreen = ({ title }) => {
    const classes = classNames(
        'text-center',
        'text-md-left',
        'mb-sm-0'
    );

    return (
        <Col className={classes} style={{ background: 'transparent' }}>
            <div className='center-block' style={{ background: 'transparent' }}>
                <div style={{ width: '80px', margin: '20% auto 0' }}>
                    <Oval
                        height={80}
                        width={80}
                        color="#185ADB"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#185ADB"
                        strokeWidth={3}
                        strokeWidthSecondary={3}
                    />
                </div>
            </div>
        </Col>
    )
};

export default LoadingScreen